import React from 'react';

import { Row } from 'components/Layout';
import Text from 'components/Text';

import { StyledModal } from './styles';

interface Props {
  headerImg?: React.ReactNode;
  body: React.ReactNode;
  onCancel: () => void;
  width?: string;
  visible: boolean;
  closeContent?: React.ReactNode;
}

const ContentModal = ({
  headerImg,
  body,
  onCancel,
  width,
  visible,
  closeContent,
}: Props) => {
  const hasHeaderImg = Boolean(headerImg);

  return (
    <StyledModal
      onCancel={onCancel}
      centered={true}
      width={width}
      open={visible}
      destroyOnClose={true}
      maskClosable={false}
      closeIcon={
        <Row
          $position="absolute"
          $bgColor="black"
          $width="120px"
          $justifyContent="space-between"
          $height="40px"
          $padding="0px 15px 0px 15px"
          $borderRadius="40px"
          $topPos="-70px"
          $rightPos="-15px"
        >
          {closeContent ? (
            closeContent
          ) : (
            <Text alignSelf="center" fontWeight="bold" color="white">
              Close &nbsp;&#x2715;
            </Text>
          )}
        </Row>
      }
    >
      {hasHeaderImg && headerImg}
      {body}
    </StyledModal>
  );
};

export default ContentModal;
