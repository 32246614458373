import { useState } from 'react';

import { useAppContext } from 'utils/context';

export const useAgeRestriction = () => {
  const [isAgeRestricted, setAgeRestricted] = useState(false);
  const { ageConfirmed, setAgeConfirmed } = useAppContext();

  const confirmAge = (onConfirm: () => void) => {
    setAgeRestricted(false);
    setAgeConfirmed(true);
    onConfirm();
  };

  return {
    ageConfirmed,
    isAgeRestricted,
    setAgeRestricted,
    confirmAge,
  };
};
