import React, { useContext } from 'react';
import { useLocation } from 'react-router';

import Closed from 'containers/Closed';
import LandingLayout from 'containers/LandingLayout';
import Routes from 'router/routes';
import { HoursContext } from 'utils/context/operationHours';

const WHITELISTED_URLS = [Routes.LOGIN.path, Routes.SETTINGS.path];

const ClosedPage = () => {
  const { isClosed } = useContext(HoursContext);
  const location = useLocation();

  const showClosedModal =
    isClosed && !WHITELISTED_URLS.includes(location.pathname);

  if (!showClosedModal) return null;

  return (
    <LandingLayout logout>
      <Closed />
    </LandingLayout>
  );
};

export default ClosedPage;
