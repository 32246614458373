import React from 'react';
import { useNavigate } from 'react-router';

import Button from 'components/Button';
import InfoModal from 'components/InfoModal';
import { ItemWrapper } from 'components/Layout';
import Text from 'components/Text';
import Routes from 'router/routes';
import { Cart } from 'utils/apollo/models';

import { Box } from '../styles';

interface Props {
  cart: Cart;
  openModal: boolean;
  setOpenModal: React.Dispatch<boolean>;
}

const CheckoutButton = ({ cart, openModal, setOpenModal }: Props) => {
  const navigate = useNavigate();

  const onClick = () =>
    cart.items.length > 0 ? navigate(Routes.CHECKOUT.path) : setOpenModal(true);

  return (
    <>
      <Box bgColor="black" height={250} onClick={onClick}>
        <Text
          textAlign="center"
          color="white"
          fontSize="h2"
          fontWeight="semiBold"
        >
          Checkout
        </Text>
      </Box>
      <InfoModal
        visible={openModal}
        title="No items in cart"
        basic={false}
        message="Go back and add some items to your cart to continue to checkout!"
      >
        <ItemWrapper $display="flex" $flexDirection="row">
          <Button text="Go Back" primary onClick={() => setOpenModal(false)} />
        </ItemWrapper>
      </InfoModal>
    </>
  );
};

export default CheckoutButton;
