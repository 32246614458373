import firebase from 'firebase/compat/app';

import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import 'firebase/compat/database';
import { Tenant } from 'codegen/generated/graphql';

enum Env {
  STAGING = 'staging',
  DEMO = 'demo',
  PRODUCTION = 'production',
}

const configs = {
  [Env.STAGING]: {
    apiKey: 'AIzaSyAqdjgNjPhz8ibroCcqUkCVtzHuEivVq4c',
    authDomain: 'crave-staging.firebaseapp.com',
    databaseURL: 'https://crave-staging.firebaseio.com',
    projectId: 'crave-staging',
    storageBucket: 'crave-staging.appspot.com',
    messagingSenderId: '341409816227',
    appId: '1:341409816227:web:89e7645a3eb06ee1f1a916',
    measurementId: 'G-BJYCN91J82',
  },
  [Env.DEMO]: {
    apiKey: 'AIzaSyD2VvTPkitsnswSKcMe4_oR47wIvwsQZIE',
    authDomain: 'crave-demo-a6c18.firebaseapp.com',
    databaseURL: 'https://crave-demo-a6c18.firebaseio.com',
    projectId: 'crave-demo-a6c18',
    storageBucket: 'crave-demo-a6c18.appspot.com',
    messagingSenderId: '986180262122',
    appId: '1:986180262122:web:ecfa368fccdaf37cf01f93',
    measurementId: 'G-WMCWGYHEM6',
  },
  [Env.PRODUCTION]: {
    apiKey: 'AIzaSyCKeO2tugyfixPC-c7MlE478WWZzrl2KNA',
    authDomain: 'crave-production.firebaseapp.com',
    databaseURL: 'https://crave-production.firebaseio.com',
    projectId: 'crave-production',
    storageBucket: 'crave-production.appspot.com',
    messagingSenderId: '565640797699',
    appId: '1:565640797699:web:ebc15c56b32f4af466f836',
    measurementId: 'G-7SHQMJR85L',
  },
};

const firebaseConfig =
  configs[(process.env.REACT_APP_CUSTOM_ENV as Env) || configs.staging];

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const analytics = firebase.analytics();
const database = firebase.database();

export const setTenantToAuth = (tenant: Tenant) => {
  if (!tenant.withoutTenantAuth) {
    auth.tenantId = tenant.id;
  }
};

const { logEvent } = analytics;
export { analytics, logEvent, auth, database };
