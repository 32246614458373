import React from 'react';

import Button from 'components/Button';
import { ItemWrapper, Row } from 'components/Layout';
import Text from 'components/Text';

import { Modal } from './styles';

const ActionModal: React.FC<{
  title: string;
  buttonText?: string;
  onClickAction?: () => void;
  onClickBack?: () => void;
  visible: boolean;
  error?: string;
  width?: string;
  children: React.ReactNode;
}> = ({
  children,
  title,
  buttonText,
  onClickAction,
  onClickBack,
  visible,
  width,
  error,
}) => {
  return (
    <Modal
      title={title}
      open={visible}
      footer={null}
      centered
      closable={false}
      destroyOnClose={true}
      width={width}
    >
      {children}
      {error && (
        <Row $width="400px" $margin="0 auto" $padding="0 0 30px 0">
          <Text textAlign="center" color="warning">
            {error}
          </Text>
        </Row>
      )}
      <ItemWrapper $display="flex" $flexDirection="column">
        {buttonText && (
          <Button text={buttonText} primary onClick={onClickAction} />
        )}

        <Button text={'Go Back'} skeleton onClick={onClickBack} />
      </ItemWrapper>
    </Modal>
  );
};

export default ActionModal;
