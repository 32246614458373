import React, { createContext, useContext, useEffect, useState } from 'react';

import { useReactiveVar } from '@apollo/client';

import { useKioskCartCostsLazyQuery } from 'codegen/generated/graphql';
import { menuItemVars } from 'utils/apollo/localStore';
import { cartMutations } from 'utils/apollo/mutations';

import { AppContext } from '.';

interface Props {
  items: any;
  recalculate: boolean;
  setRecalculate: (val: boolean) => void;
}

export const CartContext = createContext<Props>({
  items: [],
  recalculate: false,
  setRecalculate: (_) => undefined,
});

export const useCartContext = () => useContext(CartContext);

type ProviderProps = {
  children: React.ReactNode;
};
const CartContextProvider = ({ children }: ProviderProps) => {
  const { cart } = useContext(AppContext);
  const menuItems = useReactiveVar(menuItemVars);
  const [recalculate, setRecalculate] = useState(false);

  const [fetchCartCosts, { data }] = useKioskCartCostsLazyQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!recalculate) return;
    cart.items.forEach((item) => (item.quantity = 1));

    fetchCartCosts({
      variables: {
        cartInput: JSON.stringify(cart),
      },
    });
    setRecalculate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recalculate]);

  useEffect(() => {
    if (data !== undefined)
      //FIXME: use object for parameters here
      cartMutations.editCartCosts(
        1,
        data.kioskCartCosts.subtotal,
        data.kioskCartCosts.tax,
        data.kioskCartCosts.fee,
        data.kioskCartCosts.promoDiscount,
        data.kioskCartCosts.giftCardsDiscount,
        null,
        data.kioskCartCosts.delivery,
        true,
        data.kioskCartCosts.tip,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <CartContext.Provider
        value={{ items: menuItems, setRecalculate, recalculate }}
      >
        {children}
      </CartContext.Provider>
    </>
  );
};

export default CartContextProvider;
