import React from 'react';

import { sumBy } from 'lodash';

import {
  Allergy,
  DishType,
  KitchenMenuItemQuery,
} from 'codegen/generated/graphql';
import Button from 'components/Button';
import { Row, Column } from 'components/Layout';
import QuantitySelector from 'components/QuantitySelector';
import { MenuItem } from 'utils/apollo/models';

import ItemDetail from './ItemDetail';
import ItemExtras from './ItemExtras';
import MealPackItems from './MealPackItems';
import { UpsellItems } from './UpsellItems';
import { UpsellItem } from '../types';

interface Props {
  name: string;
  description: string;
  allergies: Allergy[];
  dishTypes: DishType[];
  price: number;
  extras: KitchenMenuItemQuery['customerKitchenMenuItem']['extras'];
  isCheckout: boolean | undefined;
  cartMenuItem: MenuItem | undefined;
  menuItem: MenuItem;
  mealPackItems: any;
  itemQuantity: number;
  soldOut: boolean;
  upsellItems: UpsellItem[];
  isUpsellItem: boolean;
  loading: boolean;
  setItemQuantity: (quantity: number) => void;
  viewUpsellItem: (id: string) => void;
  handleAddCartClick: () => void;
}

const MenuItemModalBody = ({
  name,
  description,
  allergies,
  dishTypes,
  price,
  extras,
  isCheckout,
  cartMenuItem,
  menuItem,
  mealPackItems,
  itemQuantity,
  soldOut,
  upsellItems,
  isUpsellItem,
  loading,
  setItemQuantity,
  handleAddCartClick,
  viewUpsellItem,
}: Props) => {
  const extrasMinimumMet = () => {
    if (!extras.length) {
      return true;
    } else {
      const extrasWithRequiredMin = extras.filter((extra) => extra.required);

      if (!extrasWithRequiredMin.length) {
        return true;
      } else {
        const itemWithExtras = isCheckout ? cartMenuItem : menuItem;
        let minimumMet = true;

        extrasWithRequiredMin.forEach((extra) => {
          //if extra is single-select, make sure one of it's choices is selected
          const singleMinMet = () => {
            if (!extra.singleOption) {
              return true;
            }

            return extra.items.some(
              (extraItem) =>
                itemWithExtras?.selectedExtras.some(
                  (selected) => selected.item.id === extraItem.id,
                ),
            );
          };

          //if extra is multi-select, make sure sumBy of it's selected items is greater than or equal to extra.min
          const multiMinMet = () => {
            if (extra.singleOption) {
              return true;
            }
            const selectedChoices = itemWithExtras?.selectedExtras.filter(
              (selected) => selected.parentId === extra.id,
            );

            return sumBy(selectedChoices, 'quantity') >= extra.minimum;
          };

          if (!singleMinMet() || !multiMinMet()) {
            minimumMet = false;
          }
        });

        return minimumMet;
      }
    }
  };

  if (loading) {
    return null;
  }

  return (
    <Column $padding="40px 64px 0 !important">
      <ItemDetail
        name={name}
        description={description}
        allergies={allergies}
        dishTypes={dishTypes}
        price={price}
      />

      {extras && extras.length > 0 && (
        <ItemExtras
          extras={extras}
          isCheckout={isCheckout}
          cartMenuItem={cartMenuItem}
          menuItem={menuItem}
        />
      )}
      {mealPackItems && mealPackItems.length > 0 && (
        <MealPackItems
          isCheckout={isCheckout}
          menuItem={menuItem}
          cartMenuItem={cartMenuItem}
          mealPackItems={mealPackItems}
        />
      )}
      <Row $width="fit-content" align="middle">
        <QuantitySelector
          size="middle"
          quantity={itemQuantity}
          handleAddBtnClick={() => {
            setItemQuantity(itemQuantity + 1);
          }}
          handleMinusBtnClick={() => {
            if (itemQuantity > 1) {
              setItemQuantity(itemQuantity - 1);
            }
          }}
        />
        <Button
          display="inline"
          primary
          fontSize="h3"
          marginLeft={34}
          text={isCheckout ? 'Update' : soldOut ? 'Sold Out' : 'Add to Cart'}
          onClick={handleAddCartClick}
          disabled={soldOut || !extrasMinimumMet()}
        />
      </Row>
      {!isUpsellItem && (
        <UpsellItems items={upsellItems} viewItem={viewUpsellItem} />
      )}
    </Column>
  );
};

export default MenuItemModalBody;
