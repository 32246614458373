import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from 'authContext';
import { User } from 'codegen/generated/graphql';
import InfoModal from 'components/InfoModal';
import Routes from 'router/routes';
import { useAppContext } from 'utils/context';
import { getKioskDeliveryAddress } from 'utils/helpers';

import CheckoutButton from './components/CheckoutButton';
import OrderInfo from './components/OrderInfo';
import PriceSection from './components/PriceSection';
import SideCart from './components/SideCart';

import { SideBarContainer } from './styles';

type Props = {
  orderName: string;
};

const SideBar = ({ orderName }: Props) => {
  const { user, isDelivery } = useContext(AuthContext);
  const [showStartModal, setShowStartModal] = useState(false);
  const [showNoItemModal, setShowNoItemModal] = useState(false);
  const navigate = useNavigate();
  const { cart, setStartNewOrder } = useAppContext();

  const deliveryAddress = getKioskDeliveryAddress(user as User, isDelivery);

  const deliveryFeeLabel = isDelivery
    ? {
        id: 'delivery',
        label: 'Delivery Fee',
        value: `$${cart.delivery}`,
      }
    : null;

  const summaryValues = [
    {
      id: 'subtotal',
      label: 'Subtotal',
      value: `$${cart.subTotal.toFixed(2)}`,
    },
    {
      id: 'tax',
      label: 'Tax & Fees',
      value: `$${(cart.tax + cart.fee).toFixed(2)}`,
    },
    deliveryFeeLabel,
  ];

  const totalValue = {
    label: 'Total',
    value: cart.total.toFixed(2),
  };

  return (
    <SideBarContainer>
      <OrderInfo
        isDelivery={isDelivery}
        deliveryAddress={deliveryAddress || { line1: '', line2: '' }}
        setOpenModal={setShowStartModal}
        orderName={orderName}
      />
      <SideCart />
      <PriceSection subtotal={summaryValues} total={totalValue} />
      <CheckoutButton
        cart={cart}
        openModal={showNoItemModal}
        setOpenModal={setShowNoItemModal}
      />
      <InfoModal
        visible={showStartModal}
        title="Are you sure you want to start a new order?"
        buttonText="Start Over"
        onClickAction={() => {
          setStartNewOrder(true);
          navigate(Routes.MENU.path);
          setShowStartModal(false);
        }}
        onClickBack={() => setShowStartModal(false)}
        basic
      />
    </SideBarContainer>
  );
};

export default SideBar;
