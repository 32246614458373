import { message } from 'antd';
import { uniq } from 'lodash';

import {
  OrderValidationError,
  OrderValidationErrorResponseType,
  PromoValidationError,
  PromoValidationErrorWithCode,
} from 'codegen/generated/graphql';

import { ORDER_VALIDATION_ERRORS, PROMO_VALIDATION_ERRORS } from './constants';

export const getQueryErrorMessage = (e: unknown) =>
  `Validation failed: ${(e as Error)?.message || 'Unknown error'}`;

export const formatValidationErrors = (
  promoErrors?: PromoValidationErrorWithCode[] | null,
  orderErrors?: OrderValidationErrorResponseType[] | null,
  queryErrors?: readonly unknown[] | null,
): PromoValidationError[] | OrderValidationError[] | string[] =>
  promoErrors?.length || orderErrors?.length || queryErrors?.length
    ? uniq([
        ...(promoErrors?.map(({ error }) => error) ?? []),
        ...(orderErrors?.map(({ code }) => code) ?? []),
        ...(queryErrors?.map(getQueryErrorMessage) ?? []),
      ])
    : [];

const isPromoValidationError = (value: string): value is PromoValidationError =>
  (Object.values(PromoValidationError) as string[]).includes(value);

const isOrderValidationError = (value: string): value is OrderValidationError =>
  (Object.values(PromoValidationError) as string[]).includes(value);

export const reportValidationErrors = (
  ...errors: Parameters<typeof formatValidationErrors>
) => {
  const [promoErrors, orderErrors, queryErrors] = errors;
  const errorCodes = formatValidationErrors(
    promoErrors,
    orderErrors,
    queryErrors,
  );

  errorCodes.forEach((e) => {
    let messageText: string;
    if (isPromoValidationError(e)) {
      messageText = PROMO_VALIDATION_ERRORS[e];
    } else if (isOrderValidationError(e)) {
      messageText = ORDER_VALIDATION_ERRORS[e];
    } else {
      messageText = e;
    }

    message.error(messageText);
  });

  return errorCodes.length;
};
