import React, { useEffect } from 'react';

import { message } from 'antd';
import { orderBy } from 'lodash';
import chunk from 'lodash/chunk';

import {
  useKitchensQuery,
  useDashboardQuery,
  KitchenStatus,
} from 'codegen/generated/graphql';
import Carousel from 'components/Carousel';
import KitchenCards from 'containers/KitchenCards';
import MenuLayout from 'containers/MenuLayout';
import MenuView from 'containers/MenuView';
import { KITCHENS_MAX } from 'utils/constants';
import { useAppContext } from 'utils/context';

const Menu = () => {
  const { selectedKitchen, setSelectedKitchen } = useAppContext();
  const {
    data: kitchens,
    loading,
    error,
  } = useKitchensQuery({ pollInterval: 60 * 1000 });

  const {
    data: dashboardItems,
    loading: dashLoading,
    error: dashError,
  } = useDashboardQuery({ pollInterval: 60 * 1000 });

  const { cart } = useAppContext();

  const orderedKitchens = orderBy(
    kitchens?.customerKitchens,
    ['sortId'],
    ['asc'],
  );

  const activeKitchens = orderedKitchens?.filter(
    (i) => i.status === KitchenStatus.Active,
  );

  useEffect(() => {
    if (!loading && error) {
      message.error(
        'Failed to load the available kitchens, please try reloading the page',
      );
    }
    if (!dashLoading && dashError) {
      message.error('Failed to load the menu, please try reloading the page');
    }
  }, [loading, error, dashLoading, dashError]);

  const kitchenSlides = chunk(activeKitchens, KITCHENS_MAX);
  const specialMenu = dashboardItems?.customerSpecialMenu ?? [];

  return (
    <MenuLayout orderName={cart.orderName ?? ''}>
      <Carousel>
        {kitchenSlides.map((i, index) => {
          return (
            <div key={index}>
              <KitchenCards
                index={index}
                kitchens={i}
                selectedKitchen={selectedKitchen}
                setSelectedKitchen={setSelectedKitchen}
              />
            </div>
          );
        })}
      </Carousel>
      <MenuView data={specialMenu} selectedKitchen={selectedKitchen} />
    </MenuLayout>
  );
};

export default Menu;
