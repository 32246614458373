import React from 'react';

import { Row } from 'components/Layout';
import Text from 'components/Text';
import { priceFormatter } from 'utils/formatHelpers';

interface Props {
  selectedExtras: any;
}

const CartExtraItems = ({ selectedExtras }: Props) => {
  return (
    <>
      {selectedExtras.map((extra: any) => {
        return (
          <Row
            key={extra.item.id}
            $justifyContent="space-evenly"
            $padding="2px 6px"
          >
            <Row $justifyContent="flex-start">
              <Text marginRight="md" fontSize="xs" truncateText>
                {extra.quantity}X
              </Text>
              <Text fontSize="xs">{extra.item.name}</Text>
            </Row>
            <Text fontSize="xs">
              {priceFormatter(extra.item.price * extra.quantity)}
            </Text>
          </Row>
        );
      })}
    </>
  );
};

export default CartExtraItems;
