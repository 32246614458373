import React from 'react';

import { Allergy, DishType } from 'codegen/generated/graphql';
import { Row, ItemWrapper } from 'components/Layout';
import MenuItemTag from 'components/MenuItemTag';
import Text from 'components/Text';
import { allergyValues, dishTypeValues } from 'utils/constants';

import ResturauntLogo from './RestaurantLogo';

interface Props {
  name: string;
  description: string;
  allergies: Allergy[];
  dishTypes: DishType[];
  logoSrc: string;
}

const MealPackItemDetail = ({
  name,
  description,
  allergies,
  dishTypes,
  logoSrc,
}: Props) => {
  return (
    <ItemWrapper
      $flexDirection="column"
      $display="flex"
      $align="start"
      $margin="15px 0px 0px 0px"
    >
      <ItemWrapper
        $flexDirection="row"
        $display="flex"
        $align="start"
        $margin="15px 0px 0px 0px"
      >
        <ResturauntLogo logoSrc={logoSrc} roundBorder />
        <Text
          alignSelf="center"
          marginLeft={10}
          fontSize="h2"
          fontWeight="bold"
        >
          {name}
        </Text>
      </ItemWrapper>
      <Text fontSize="lg" fontWeight="normal" marginTop="lg">
        {description}
      </Text>
      <Row
        $width="fit-content"
        $flex={2}
        style={{ marginTop: 30 }}
        $justifyContent="flex-start"
      >
        {allergies.map((allergy) => {
          const tagLabel = allergyValues.find((i) => i.value === allergy);

          return tagLabel ? (
            <MenuItemTag key={allergy} tagLabel={tagLabel.label} />
          ) : null;
        })}
        {dishTypes.map((dishType) => {
          const tagLabel = dishTypeValues.find((i) => i.value === dishType);

          return tagLabel ? (
            <MenuItemTag key={dishType} tagLabel={tagLabel.label} />
          ) : null;
        })}
      </Row>
    </ItemWrapper>
  );
};

export default MealPackItemDetail;
