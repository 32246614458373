import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import Button from 'components/Button';
import { Input } from 'components/Input';
import { ItemWrapper } from 'components/Layout';
import Text from 'components/Text';
import Routes from 'router/routes';
import { AppContext } from 'utils/context';
import { auth } from 'utils/firebase';

interface Values {
  email: string;
  password: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required('Please enter your email'),
  password: Yup.string().required('Please enter your password'),
});

const LoginCont = () => {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState('');
  const { setStartNewOrder } = useContext(AppContext);

  const { values, handleSubmit, setFieldValue, errors, touched, handleBlur } =
    useFormik<Values>({
      initialValues: { email: '', password: '' },
      onSubmit: async () => {
        setLoginError('');
        try {
          await auth.signInWithEmailAndPassword(values.email, values.password);
          setStartNewOrder(true);
          navigate(Routes.MENU.path);
        } catch (error) {
          setLoginError('Incorrect email or password');
        }
      },
      validationSchema,
    });

  return (
    <>
      <ItemWrapper $flex={0.7}>
        <Text fontSize="h2" color="white" textAlign="center" fontWeight="bold">
          Hello! Please log in to
        </Text>
        <Text fontSize="h2" color="white" textAlign="center" fontWeight="bold">
          open this kiosk.
        </Text>
      </ItemWrapper>
      <ItemWrapper $flex={0.6}>
        <Input
          name="email"
          onChange={(e) => setFieldValue('email', e)}
          value={values.email}
          placeholder={'Email'}
          bordered={false}
          width="650px"
          align="left"
        />
        {touched.email && errors.email && (
          <Text
            color="warning"
            textAlign="left"
            marginTop="md"
            marginLeft="xl"
            spaceAfter="md"
            displayFlex={false}
          >
            {errors.email}
          </Text>
        )}
      </ItemWrapper>
      <ItemWrapper $flex={1}>
        <Input
          name="password"
          type="password"
          onChange={(e) => setFieldValue('password', e)}
          value={values.password}
          placeholder={'Password'}
          bordered={false}
          onBlur={handleBlur}
          width="650px"
          align="left"
        />
        {touched.password && errors.password && (
          <Text
            color="warning"
            textAlign="left"
            marginLeft="xl"
            marginTop="md"
            spaceAfter="md"
            displayFlex={false}
          >
            {errors.password}
          </Text>
        )}
        <div
          onClick={() => navigate(Routes.RESET_PASSWORD.path)}
          // TODO: Extract style
          style={{ paddingTop: '30px' }}
        >
          <Text color="white" textAlign="center">
            Forget your password?
          </Text>
        </div>
      </ItemWrapper>
      {loginError && (
        <ItemWrapper $flex={0.5}>
          <Text
            color="warning"
            textAlign="left"
            marginLeft="xl"
            spaceAfter="md"
            displayFlex={false}
          >
            {loginError}
          </Text>
        </ItemWrapper>
      )}
      <ItemWrapper $align="center" $flex={2}>
        <Button
          onClick={() => handleSubmit()}
          text="Log In"
          disabled={errors.email != null || errors.password != null}
        />
      </ItemWrapper>
    </>
  );
};

export default LoginCont;
