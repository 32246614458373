import React from 'react';

import { Divider } from 'antd';

import { ItemWrapper } from 'components/Layout';
import { MenuItem } from 'utils/apollo/models';

import ItemExtras from './ItemExtras';
import MealPackItemDetail from './MealPackItemDetail';

interface Props {
  menuItem: MenuItem;
  cartMenuItem: MenuItem | undefined;
  mealPackItems: any;
  isCheckout?: boolean;
}

const MealPackItems = ({
  menuItem,
  cartMenuItem,
  mealPackItems,
  isCheckout,
}: Props) => {
  return (
    <ItemWrapper>
      {mealPackItems.map((mpItem: any, i: number) => (
        <ItemWrapper key={i}>
          <MealPackItemDetail
            name={mpItem.name}
            description={mpItem.description}
            allergies={mpItem.allergies}
            dishTypes={mpItem.dishTypes}
            logoSrc={mpItem.imgUrl}
          />
          {mpItem.extras.length > 0 && (
            <ItemExtras
              extras={mpItem.extras}
              isCheckout={isCheckout}
              cartMenuItem={cartMenuItem}
              menuItem={menuItem}
              mealPackItemId={mpItem?.id}
            />
          )}
          <Divider />
        </ItemWrapper>
      ))}
    </ItemWrapper>
  );
};

export default MealPackItems;
