import React from 'react';

import { useMenuItemDetails } from 'hooks/useMenuItemDetails';

import ContentModal from 'components/ContentModal';
import { Logo } from 'components/Image';
import InfoModal from 'components/InfoModal';
import Text from 'components/Text';
import { MenuItem } from 'utils/apollo/models';

import MenuItemModalBody from './components/MenuItemModalBody';
import { setItemQuantity } from './helpers';

interface Props {
  id?: string;
  kitchenMenuItemId: string;
  visible: boolean;
  handleModalClose: () => void;
  cartMenuItem?: MenuItem;
  isCheckout?: boolean;
}

const MenuItemModal = ({
  id,
  handleModalClose,
  visible,
  cartMenuItem,
  isCheckout,
  kitchenMenuItemId,
}: Props) => {
  const {
    isAgeRestricted,
    menuItemDetails,
    menuItem,
    quantity,
    soldOut,
    menuItemImg,
    upsellItemId,
    upsellItems,
    mealPackItems,
    loading,
    handleClose,
    handleAddCartClick,
    handleAgeConfirm,
    setAgeRestricted,
    setUpsellItemId,
  } = useMenuItemDetails({
    kitchenMenuItemId,
    cartMenuItem,
    isCheckout,
    handleModalClose,
  });

  return (
    <>
      <ContentModal
        visible={visible}
        width="860px"
        onCancel={handleClose}
        body={
          <MenuItemModalBody
            name={menuItemDetails?.name ?? ''}
            description={menuItemDetails?.description ?? ''}
            allergies={menuItemDetails?.allergies ?? []}
            dishTypes={menuItemDetails?.dishTypes ?? []}
            price={menuItemDetails?.price ?? 0}
            extras={menuItemDetails?.extras ?? []}
            isCheckout={isCheckout}
            cartMenuItem={cartMenuItem}
            menuItem={menuItem}
            itemQuantity={quantity}
            soldOut={soldOut}
            mealPackItems={mealPackItems}
            upsellItems={upsellItems}
            isUpsellItem={Boolean(upsellItemId)}
            viewUpsellItem={setUpsellItemId}
            setItemQuantity={(quantity) =>
              setItemQuantity({
                quantity,
                menuItemId: menuItem.id,
                cartMenuItemId: id,
                isCheckout,
              })
            }
            handleAddCartClick={() => setAgeRestricted(handleAddCartClick())}
            loading={loading}
          />
        }
        headerImg={
          <Logo
            src={menuItemImg || './svg/menu-item.svg'}
            width="860px"
            height="500px"
          />
        }
        closeContent={
          upsellItemId ? (
            <Text alignSelf="center" fontWeight="bold" color="white">
              {'<'} Back
            </Text>
          ) : null
        }
      />
      <InfoModal
        title="You must be at least 21 years old to order this item."
        visible={isAgeRestricted}
        buttonText="I am 21 or older"
        basic={true}
        message="Please have your ID ready for our staff when you pick up your items."
        onClickBack={() => setAgeRestricted(false)}
        onClickAction={handleAgeConfirm}
      />
    </>
  );
};

export default MenuItemModal;
