import { User } from 'codegen/generated/graphql';

export const handleKeyPress = (e: any, input: string) => {
  if (e === '{bksp}') {
    input = input.slice(0, -1);
  } else {
    input = input + e;
  }

  return input;
};

export const menuItemIdLogger = (id: string | undefined) => {
  const env = process.env.REACT_APP_CUSTOM_ENV;
  if (env === 'dev' || 'staging' || 'demo') {
    // eslint-disable-next-line no-console
    console.info('Menu Item Id:', id);
  }
};

export const getKioskDeliveryAddress = (user?: User, isDelivery?: boolean) => {
  if (!user || !user?.customerInfo || !isDelivery) {
    return;
  }

  const defaultAddress = user?.customerInfo.addresses.find(
    (address) => address.id === user?.customerInfo?.defaultAddressId,
  );

  if (!defaultAddress) {
    return;
  }

  return {
    line1: user?.name ?? defaultAddress?.street,
    line2: `${defaultAddress?.locality},  ${defaultAddress?.region}`,
  };
};
