export const priceFormatter = (amount: number) =>
  `$${(Math.round(amount * 100) / 100).toFixed(2)}`;

export const prettifyTime = (date: Date) =>
  date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });

export const formatPhoneNumber = (value: string) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return '+1 ' + phoneNumber;
  if (phoneNumberLength < 7) {
    return `+1 (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  return `+1 (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6,
  )}-${phoneNumber.slice(6, 10)}`;
};

export const cleanPhoneNumber = (value: string) => {
  const phoneNumber = value.replace('+1 ', '').replace(/[^\d]/g, '');
  if (phoneNumber.length > 9) {
    return phoneNumber.slice(0, 10);
  }

  return phoneNumber;
};
