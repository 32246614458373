import React from 'react';

import { Logo } from 'components/Image';

interface Props {
  logoSrc: string;
  roundBorder: boolean;
}

const RestaurantLogo = ({ logoSrc, roundBorder }: Props) => {
  return (
    <Logo
      src={logoSrc}
      $borderRadius={roundBorder ? 'half' : 'none'}
      width="100px"
      height="100px"
      objectFit={roundBorder ? 'cover' : 'contain'}
    />
  );
};

export default RestaurantLogo;
