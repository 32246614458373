import React, { useState } from 'react';

import { Form, message } from 'antd';
import { FormProps } from 'antd/lib/form/Form';

import {
  OrderInfoFragment,
  useSendReceiptMutation,
} from 'codegen/generated/graphql';
import Button from 'components/Button';
import { Checkbox } from 'components/CheckBox';
import ContentModal from 'components/ContentModal';
import InfoModal from 'components/InfoModal';
import { Input } from 'components/Input';
import { Row, ItemWrapper } from 'components/Layout';
import Text from 'components/Text';
import { prettifyTime } from 'utils/formatHelpers';

import { Iframe } from './styles';
import { ButtonLink } from '../SideBar/styles';

interface Props {
  visible: boolean;
  closeFn: () => void;
  orderInfo?: OrderInfoFragment | null;
}

const ConfirmModal = ({ visible, closeFn, orderInfo }: Props) => {
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [checked, setChecked] = useState(false);
  const [sendReceipt, { loading }] = useSendReceiptMutation({
    onCompleted: () => {
      setSuccess(true);
    },
  });
  const [success, setSuccess] = useState(false);

  const [form] = Form.useForm();

  const { Item } = Form;

  const terms = (
    <ButtonLink
      $padding="0px 0px"
      onClick={() => {
        setShowTerms(true);
      }}
    >
      terms and conditions
    </ButtonLink>
  );
  const privacy = (
    <ButtonLink
      $padding="0px 0px"
      onClick={() => {
        setShowPrivacy(true);
      }}
    >
      privacy policy
    </ButtonLink>
  );

  const onFinish: FormProps['onFinish'] = async ({ email }) => {
    try {
      await sendReceipt({
        variables: {
          email,
          orderId: orderInfo?.id || '',
          checked,
        },
      });
    } catch (error) {
      message.error('An error occured please try again');
      form.resetFields(['email']);
    }
  };

  return (
    <>
      <InfoModal visible={visible} title="You're all set!" width="688px">
        <Text textAlign="center">Order Name:</Text>
        <Text
          textAlign="center"
          fontWeight="bold"
          fontSize="h1"
          spaceAfter="xl"
        >
          {orderInfo?.orderName}
        </Text>
        <ItemWrapper
          $display="flex"
          $flexDirection="row"
          $padding="0px 0px 40px 0px"
        >
          <ItemWrapper>
            <Text textAlign="center">Order Number:</Text>
            <Text fontWeight="bold" fontSize="h1">
              {`#${orderInfo?.shortId}`}
            </Text>
          </ItemWrapper>
          <ItemWrapper>
            <Text textAlign="center">Estimated Time:</Text>
            <Text fontWeight="bold" fontSize="h1">
              {prettifyTime(new Date(orderInfo?.fulfillmentBy))}
            </Text>
          </ItemWrapper>
        </ItemWrapper>
        <Text textAlign="center" spaceAfter="xs">
          Enter your email below to get a receipt.
        </Text>
        <Form onFinish={onFinish} form={form} requiredMark={false}>
          <Item
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                validateTrigger: 'onBlur',
                message:
                  'Please enter your email address in the format: yourname@example.com',
              },
            ]}
          >
            <Input
              placeholder="youremail@email.com"
              width="425px"
              height="49px"
              align="center"
              placeholderColor="gray"
              padding="5px 20px"
              autoComplete="off"
            />
          </Item>
          <ItemWrapper $display="flex" $flexDirection="row" $align="baseline">
            <Checkbox
              $whiteCheck={checked}
              $borderColor="black"
              onClick={() => setChecked(!checked)}
              checked={checked}
            />
            <Text textAlign="center" spaceAfter="xs">
              I would like to receive specials and promotions.
            </Text>
          </ItemWrapper>

          <Text textAlign="center" fontSize="xs" spaceAfter="xs">
            Message and data rates may apply.
          </Text>
          <Text textAlign="center" fontSize="xs" spaceAfter="xs">
            Read our {terms} and {privacy}.
          </Text>
          <ItemWrapper
            $display="flex"
            $flexDirection="column"
            $padding="20px 0px 0px"
          >
            <Button
              text="Send me my receipt"
              fontSize="lg"
              border={20}
              loading={loading}
              htmlType="submit"
              borderColor="black"
              padding="0 30px"
              skeleton
            />
            {success && (
              <Text
                textAlign="center"
                marginTop="md"
                spaceAfter="md"
                displayFlex={false}
              >
                Receipt Sent
              </Text>
            )}
            <Button
              text="Done"
              fontSize="lg"
              onClick={() => closeFn()}
              border={10}
              borderColor="black"
              marginT={80}
              primary
            />
          </ItemWrapper>
        </Form>
      </InfoModal>
      <ContentModal
        width="860px"
        visible={showTerms}
        body={
          <Row $padding="40px" $width="100%" $height="100%">
            <Iframe
              sandbox=""
              src="https://www.cravedelivery.com/terms-and-conditions-hidden"
            ></Iframe>
          </Row>
        }
        onCancel={() => setShowTerms(false)}
      />
      <ContentModal
        width="860px"
        visible={showPrivacy}
        body={
          <Row $padding="40px" $width="100%" $height="100%">
            <Iframe
              sandbox=""
              src="https://www.cravedelivery.com/privacy-policy-hidden"
            ></Iframe>
          </Row>
        }
        onCancel={() => setShowPrivacy(false)}
      />
    </>
  );
};

export default ConfirmModal;
