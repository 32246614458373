import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react';

import { useApolloClient } from '@apollo/client';

import { TAG_NAMES } from 'utils/constants';

import { MeDocument, MeQuery } from './codegen/generated/graphql';
import { auth } from './utils/firebase';

export type Me = NonNullable<MeQuery['me']> | null;

export interface AuthState {
  user: Me;
  loading: boolean;
  facilityId: string;
  facilityTz: string;
  isDelivery: boolean;
}

type Props = {
  children: React.ReactNode;
};

const AuthProvider = ({ children }: Props) => {
  const client = useApolloClient();
  const [state, setState] = useState<AuthState>({
    user: null,
    isDelivery: false,
    loading: true,
    facilityId: '',
    facilityTz: '',
  });

  const resetState = useCallback(() => {
    setState({
      user: null,
      loading: false,
      isDelivery: false,
      facilityId: '',
      facilityTz: '',
    });
  }, []);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (!user) return resetState();

      setState((prevState) => ({ ...prevState, loading: true }));

      try {
        const { data } = await client.query<MeQuery>({
          fetchPolicy: 'network-only',
          query: MeDocument,
          variables: {
            query: '',
            pagination: 1,
          },
        });

        if (!data?.me) return resetState();

        localStorage.setItem('facilityId', data.me.facilities[0].id);
        localStorage.setItem('facilityTz', data.me.facilities[0].timeZone);
        localStorage.setItem('facilityName', data.me.facilities[0].name);

        const tags = data.me?.customerInfo?.tags || [];
        setState({
          user: data?.me,
          isDelivery: tags.some((tag) => tag.name === TAG_NAMES.DELIVERY)
            ? true
            : false,
          loading: false,
          facilityId: data.me.facilities[0].id,
          facilityTz: data.me.facilities[0].timeZone,
        });
      } catch {
        return resetState();
      }
    });
  }, [client, resetState]);

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        loading: state.loading,
        isDelivery: state.isDelivery,
        facilityId: state.facilityId,
        facilityTz: state.facilityTz,
        logout: async () => {
          await auth.signOut();
          await client.clearStore();
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const AuthContext = createContext({
  user: null,
  loading: true,
  logout: () => {},
} as AuthState & { logout: () => Promise<void> });

export const useAuthContext = () => useContext(AuthContext);
