import React, { createContext, useState } from 'react';

import { isFacilityTck } from 'utils/helpers/formatters';

import { INITIAL_VALUES, Location } from './constants';
import { Props } from './types';

// LS = Local Storage
const LS_LOCATION_KEY = 'location';
const LS_FACILITY_ID_KEY = 'facilityId';
const LS_FACILITY_TZ_KEY = 'facilityTz';
const LS_FACILITY_NAME = 'facilityName';

export const FacilityContext = createContext<Props>(INITIAL_VALUES);

type ProviderProps = {
  children: React.ReactNode;
};

const FacilityContextProvider = ({ children }: ProviderProps) => {
  const lsDefined = typeof localStorage !== 'undefined';
  const initialValues = {
    location: ((lsDefined && localStorage.getItem(LS_LOCATION_KEY)) ||
      INITIAL_VALUES.location) as Location,
    facilityId:
      (lsDefined && localStorage.getItem(LS_FACILITY_ID_KEY)) ||
      INITIAL_VALUES.facilityId,
    facilityName:
      (lsDefined && localStorage.getItem(LS_FACILITY_NAME)) ||
      INITIAL_VALUES.facilityName,
    facilityTz:
      (lsDefined && localStorage.getItem(LS_FACILITY_TZ_KEY)) ||
      INITIAL_VALUES.facilityTz,
  };

  const [location, setLocation] = useState(initialValues.location);
  const [facilityId, setFacilityId] = useState(initialValues.facilityId);
  const [facilityName, setFacilityName] = useState(initialValues.facilityName);
  const [facilityTz, setFacilityTz] = useState(initialValues.facilityTz);
  const giftCardAvailable = !isFacilityTck(facilityName);

  // FIXME: For some reason, I couldn't get "const" working with the type forwarding - you are welcome to try for the sake of consistency
  function setInStateAndLS<T>(lsName: string, setterFn: (value: T) => void) {
    return (value: T) => {
      lsDefined && localStorage.setItem(lsName, value as unknown as string);
      setterFn(value);
    };
  }

  return (
    <FacilityContext.Provider
      value={{
        location,
        facilityId,
        facilityName,
        facilityTz,
        giftCardAvailable,
        setFacilityId: setInStateAndLS('facilityId', setFacilityId),
        setFacilityName: setInStateAndLS('facilityName', setFacilityName),
        setFacilityTz: setInStateAndLS('facilityTz', setFacilityTz),
        setLocation: setInStateAndLS('location', setLocation),
      }}
    >
      {children}
    </FacilityContext.Provider>
  );
};

export default FacilityContextProvider;
