import React from 'react';

import { Image } from 'antd';

import Button from 'components/Button';
import { ItemWrapper } from 'components/Layout';
import Text from 'components/Text';
import { StyledTextProps } from 'components/Text/styles';

import { Modal } from './styles';

export interface InfoModalProps {
  title: string;
  imageSrc?: string;
  buttonText?: string;
  cancelText?: string;
  onClickAction?: () => void;
  onClickBack?: () => void;
  visible: boolean;
  basic?: boolean;
  message?: string;
  width?: string;
  isIdleTimeOut?: boolean;
  height?: string;
  titleFontSize?: StyledTextProps['fontSize'] | string;
  children?: React.ReactNode;
}

const InfoModal = ({
  children,
  title,
  imageSrc,
  buttonText,
  cancelText,
  onClickAction,
  onClickBack,
  visible,
  basic,
  message,
  width,
  isIdleTimeOut,
  height,
  titleFontSize,
}: InfoModalProps) => {
  return (
    <Modal
      title={title}
      open={visible}
      footer={null}
      centered
      closable={false}
      destroyOnClose={true}
      width={width}
      $height={height}
      titleFontSize={titleFontSize}
    >
      {message && (
        <Text
          spaceAfter="xl"
          textAlign="center"
          fontWeight={isIdleTimeOut ? 'bold' : 'normal'}
        >
          {message}
        </Text>
      )}
      {imageSrc && <Image preview={false} src={imageSrc} />}
      {basic && (
        <ItemWrapper $display="flex" $flexDirection="row">
          {buttonText && (
            <Button text={buttonText} primary onClick={onClickAction} />
          )}
          {!isIdleTimeOut && (
            <Button
              text={cancelText ? cancelText : 'Go Back'}
              skeleton
              onClick={onClickBack}
            />
          )}
        </ItemWrapper>
      )}
      {children}
    </Modal>
  );
};

export default InfoModal;
