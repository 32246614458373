import { captureMessage, captureException } from '@sentry/browser';
import {
  loadStripeTerminal,
  Reader,
  Terminal,
  IPaymentIntent,
  ISdkManagedPaymentIntent,
  ErrorResponse,
} from '@stripe/terminal-js';

import { auth } from 'utils/firebase';

const url =
  process.env.REACT_APP_KIOSK_API_URL?.replace('/graphql', '') ??
  'http://localhost:4000';

export enum PaymentStatus {
  'DECLINED',
  'ERROR',
  'COMPLETE',
  'PROCESSING',
  'READY',
}

const fetchConnectionToken = async (): Promise<string> => {
  return fetch(`${url}/connection_token`, {
    method: 'POST',
    headers: {
      authorization: (await auth.currentUser?.getIdToken()) ?? '',
    },
  })
    .then((response) => response.json())
    .then((data) => data.secret);
};

const unexpectedDisconnect = () => {
  captureMessage('reader disconnected... attempt to reconnect.');
  // eslint-disable-next-line no-console
  console.error('ERROR: reader disconnected... attempt to reconnect.');
};

export const createTerminal = async () => {
  const StripeTerminal = await loadStripeTerminal();
  if (!StripeTerminal) {
    captureMessage('Could not initialize Stripe Terminal.');
    // eslint-disable-next-line no-console
    console.error('Could not initialize Stripe Terminal.');

    return;
  }

  const terminal = await StripeTerminal.create({
    onFetchConnectionToken: fetchConnectionToken,
    onUnexpectedReaderDisconnect: unexpectedDisconnect,
  });

  return terminal;
};

export const discoverStripeReaders = async (
  terminal: Terminal,
  simulated?: boolean,
) => {
  const readers = (await terminal.discoverReaders({
    simulated: simulated ?? false,
  })) as { discoveredReaders: Reader[] };
  const activeReaders = readers.discoveredReaders.filter(
    (reader) => reader.status === 'online',
  );

  return activeReaders;
};

export const readerRegistration = async (
  registrationCode: string,
  email: string,
  location: string,
) => {
  return fetch(`${url}/register_reader`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: (await auth.currentUser?.getIdToken()) ?? '',
    },
    body: JSON.stringify({
      registrationCode,
      label: email,
      location,
    }),
  }).then((response) => response.json());
};

export const readerConnect = async (
  terminal: Terminal | undefined,
  email: string,
  simulated: boolean,
) => {
  const readers = (await terminal?.discoverReaders({
    simulated,
  })) as { discoveredReaders: Reader[] };

  const reader = readers.discoveredReaders.find(
    (reader: Reader) => reader.label === email,
  );

  if (reader) {
    return await terminal
      ?.connectReader(reader)
      .then(() => terminal)
      .catch((e) => {
        captureException(e);
        // eslint-disable-next-line no-console
        console.error('Card reader connection failed! Please try again.');

        return;
      });
  } else {
    captureMessage('No card reader found, please connect a reader.');
    // eslint-disable-next-line no-console
    console.error('No card reader found, please connect a reader.');
  }
};

export const initPayment = async (total: number): Promise<string> => {
  return fetch(`${url}/init_payment?price=${total}`, {
    method: 'POST',
    headers: {
      authorization: (await auth.currentUser?.getIdToken()) ?? '',
    },
  })
    .then((response) => response.json())
    .then((data) => data.client_secret);
};

export const getPaymentIntent = async (
  terminal: Terminal,
  setPaymentStatus: (paymentStatus: PaymentStatus) => void,
) => {
  return fetch(`${url}/get_payment_intent`, {
    method: 'POST',
    headers: {
      authorization: (await auth.currentUser?.getIdToken()) ?? '',
    },
  })
    .then((response) => response.json())
    .then(async (data) => {
      const payMethodIntent = (await terminal?.collectPaymentMethod(
        data.data[0].client_secret,
      )) as { paymentIntent: ISdkManagedPaymentIntent };

      setPaymentStatus(PaymentStatus.PROCESSING);

      let proccessPaymentIntent:
        | { paymentIntent: IPaymentIntent }
        | ErrorResponse;
      try {
        proccessPaymentIntent = await terminal?.processPayment(
          payMethodIntent?.paymentIntent,
        );
      } catch {
        setPaymentStatus(PaymentStatus.READY);

        return false;
      }

      if ((<ErrorResponse>proccessPaymentIntent).error) {
        setPaymentStatus(PaymentStatus.DECLINED);
        captureException((<ErrorResponse>proccessPaymentIntent).error);
        // eslint-disable-next-line no-console
        console.error((<ErrorResponse>proccessPaymentIntent).error);

        return false;
      } else {
        setPaymentStatus(PaymentStatus.COMPLETE);
      }

      const getIntent = await data.data.find(
        (intent: IPaymentIntent) =>
          intent.id === payMethodIntent?.paymentIntent.id,
      );

      if (getIntent) {
        return payMethodIntent?.paymentIntent.id;
      } else {
        return false;
      }
    });
};

export const capturePayment = async (id: string, total: number) => {
  return fetch(`${url}/capture_payment?id=${id}&price=${total}`, {
    method: 'GET',
    headers: {
      authorization: (await auth.currentUser?.getIdToken()) ?? '',
    },
  }).then((response) => response.json());
};

export const cancelPaymentIntent = async (paymentIntent: string) => {
  return fetch(`${url}/cancel_payment_intent`, {
    method: 'POST',

    headers: {
      'Content-Type': 'application/json',
      authorization: (await auth.currentUser?.getIdToken()) ?? '',
    },
    body: JSON.stringify({ paymentIntent }),
  }).then((response) => response.json());
};
