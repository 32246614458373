import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { Router } from 'router';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from 'theme/GlobalStyle';

import AuthProvider from 'authContext';
import { Tenant } from 'codegen/generated/graphql';
import { withErrorBoundary } from 'components/ErrorBoundary';
import { theme } from 'theme';
import { reinitializeApolloClient } from 'utils/apollo';
import AppContext from 'utils/context';
import CartContextProvider from 'utils/context/cartContext';
import FacilityContextProvider from 'utils/context/facilityContext';
import HoursContextProvider from 'utils/context/operationHours';
import { setTenantToAuth } from 'utils/firebase';
import { initSentry } from 'utils/sentry';

import { TenantGuard } from './TenantGuard/TenantGuard';

export { client } from 'utils/apollo';

const initApp = (tenant: Tenant) => {
  initSentry();

  setTenantToAuth(tenant);

  return reinitializeApolloClient();
};

const App = () => {
  return (
    <TenantGuard>
      {({ tenant }) => {
        const client = initApp(tenant);

        return (
          <ApolloProvider client={client}>
            <GlobalStyle />
            <AppContext>
              <ThemeProvider theme={theme}>
                <AuthProvider>
                  <FacilityContextProvider>
                    <CartContextProvider>
                      <HoursContextProvider>
                        <Router />
                      </HoursContextProvider>
                    </CartContextProvider>
                  </FacilityContextProvider>
                </AuthProvider>
              </ThemeProvider>
            </AppContext>
          </ApolloProvider>
        );
      }}
    </TenantGuard>
  );
};

export default withErrorBoundary(App);
