import { Tenant } from 'codegen/generated/graphql';

const GET_TENANT_ID_URL = new URL(
  'tenant',
  process.env.REACT_APP_KIOSK_REST_API,
);

GET_TENANT_ID_URL.searchParams.set('domain', window.location.origin);

export const fetchTenant = async (): Promise<Tenant> => {
  const response = await fetch(GET_TENANT_ID_URL.toString());

  if (response.status === 200) {
    return await response.json();
  } else {
    throw new Error('Tenant ID cannot be obtained');
  }
};

const TENANT_SESSIONS_STORAGE_NAME = 'tenant';

export const getTenantFromStorage = (): Tenant | undefined => {
  const value = sessionStorage.getItem(TENANT_SESSIONS_STORAGE_NAME);

  if (value) {
    return JSON.parse(value);
  }

  return undefined;
};

export const setTenantInStorage = (tenant: Tenant) => {
  sessionStorage.setItem(TENANT_SESSIONS_STORAGE_NAME, JSON.stringify(tenant));
};

export const setMetaDataFromTenant = (tenant: Tenant) => {
  // TODO: We should consider using react Helmet if we want better SEO with AP, but I don't think we need it now
  let metaDescription = document.querySelector(
    "meta[name~='description']",
  ) as HTMLMetaElement;

  let favicon = document.querySelector("link[rel~='icon']") as HTMLLinkElement;

  let appleTouchIcon = document.querySelector(
    "link[rel~='apple-touch-icon']",
  ) as HTMLLinkElement;

  if (!favicon) {
    favicon = document.createElement('link');
    favicon.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(favicon);
  }

  if (!appleTouchIcon) {
    appleTouchIcon = document.createElement('link');
    appleTouchIcon.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(appleTouchIcon);
  }

  if (!metaDescription) {
    metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    document.getElementsByTagName('head')[0].appendChild(metaDescription);
  }

  if (tenant.favicon) {
    favicon.href = tenant.favicon;
    appleTouchIcon.href = tenant.favicon;
  }

  if (tenant.name) metaDescription.content = `${tenant.name} management portal`;

  if (tenant.metaTitle || tenant.name)
    document.title = tenant.metaTitle || tenant.name;
};
