import React from 'react';

import { KitchenMenuItemQuery } from 'codegen/generated/graphql';
import { MenuItem, ExtraItem } from 'utils/apollo/models';

import MultiExtraSelect from './MultiExtraSelect';
import SingleExtraSelect from './SingleExtraSelect';

interface Props {
  extras: KitchenMenuItemQuery['customerKitchenMenuItem']['extras'];
  isCheckout: boolean | undefined;
  cartMenuItem: MenuItem | undefined;
  menuItem: MenuItem;
  mealPackItemId?: string | undefined;
}

const ItemExtras = ({
  extras,
  isCheckout,
  cartMenuItem,
  menuItem,
  mealPackItemId,
}: Props) => {
  const getRequiredExtraForSingleSelect = (
    extra: KitchenMenuItemQuery['customerKitchenMenuItem']['extras'][0],
  ) => {
    const firstActiveChoice = extra.defaultItemId
      ? extra.items.find((choice) => choice.id === extra.defaultItemId)
      : extra.items.find((choice) => choice.isActive);

    return {
      item: firstActiveChoice,
      quantity: 1,
    } as ExtraItem;
  };

  return (
    <>
      {extras.length > 0 &&
        extras.map((extra) => {
          const menuItemExtras = extra.singleOption ? (
            <SingleExtraSelect
              extra={extra}
              key={extra.id}
              isCheckout={isCheckout}
              requiredExtra={getRequiredExtraForSingleSelect(extra)}
              menuItemId={
                !isCheckout ? menuItem.id ?? '' : cartMenuItem?.id ?? ''
              }
              menuItemExtras={
                cartMenuItem?.selectedExtras ?? menuItem.selectedExtras
              }
              mealPackItemId={mealPackItemId}
            />
          ) : (
            <MultiExtraSelect
              extra={extra}
              key={extra.id}
              isCheckout={isCheckout}
              menuItemId={
                !isCheckout ? menuItem.id ?? '' : cartMenuItem?.id ?? ''
              }
              menuItemExtras={
                cartMenuItem?.selectedExtras ?? menuItem.selectedExtras
              }
              mealPackItemId={mealPackItemId}
            />
          );

          return menuItemExtras;
        })}
    </>
  );
};

export default ItemExtras;
