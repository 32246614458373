import { Modal } from 'antd';
import styled from 'styled-components';

import { theme } from 'theme';

export const StyledModal = styled(Modal).attrs({
  centered: true,
  footer: false,
})`
  border-radius: 40px !important;
  padding: 0 !important;

  .ant-modal-content {
    border-radius: 40px !important;
    padding: 0 !important;
  }

  .ant-modal-body {
    background: ${theme.color.eggshell};
    max-height: 85vh;
    padding: 0px;
    overflow: scroll;
    scrollbar-width: 0;
    padding-bottom: 50px;
    border-radius: 40px;
  }

  .ant-modal-body::-webkit-scrollbar {
    display: none;
  }

  .ant-image-img {
    margin-top: -30px;
  }
`;
