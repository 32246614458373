import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import IdleTimeout from 'components/IdleTimeout';
import {
  USER_IDLE_COUNTDOWN,
  IDLE_EVENTS,
} from 'components/IdleTimeout/constants';
import Routes from 'router/routes';

import { AppContext } from './index';

type Props = {
  children?: React.ReactNode;
};

const IdleTimeoutHOC = ({ children }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [timeStart, setTimeStart] = useState(false);
  const { isPaymentProcessing, emptyCart, setStartNewOrder } =
    useContext(AppContext);

  const navigate = useNavigate();

  //set new event in storage
  const eventHandler = () => {
    localStorage.setItem('lastEventTimeValue', new Date().toString());
  };

  //add user event listener
  const addEvents = () => {
    IDLE_EVENTS.forEach((eventName) => {
      window.addEventListener(eventName, eventHandler);
    });

    if (!showModal) {
      //Timer function: if the time stamp of the last user event
      //is greater than the timeout value (30 sec) show idle modal
      startTimer();
      setTimeStart(false);
    }
  };

  //remove user event listener
  const removeEvents = () => {
    IDLE_EVENTS.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler);
    });
  };

  useEffect(() => {
    if (!emptyCart && timeStart) return;

    addEvents();

    return () => {
      removeEvents();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeStart, emptyCart]);

  const startTimer = () => {
    if (emptyCart || timeStart) return;
    setTimeout(() => {
      setTimeStart(true);
      const lastEventTimeValue = localStorage.getItem('lastEventTimeValue');
      const diff = lastEventTimeValue
        ? new Date().getTime() - new Date(lastEventTimeValue).getTime()
        : USER_IDLE_COUNTDOWN * 1000;

      if (
        diff > USER_IDLE_COUNTDOWN * 1000 &&
        window.location.hash !== Routes.LOGIN.hash &&
        window.location.hash !== Routes.CLOSED.hash &&
        !isPaymentProcessing
      ) {
        setShowModal(true);
      } else {
        setTimeStart(false);
        startTimer();
      }
    }, USER_IDLE_COUNTDOWN * 1000);
  };

  return (
    <>
      <IdleTimeout
        onAcceptClick={() => {
          setShowModal(false);
          setTimeStart(false);
        }}
        onTimeout={() => {
          setShowModal(false);
          navigate(Routes.MENU.path);
          setStartNewOrder(true);
        }}
        isVisible={showModal}
      />
      {children}
    </>
  );
};

export default IdleTimeoutHOC;
