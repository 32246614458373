import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from 'authContext';
import { UserRole } from 'codegen/generated/graphql';
import Routes from 'router/routes';

type Props = {
  Component: React.FC;
  isPublic?: boolean;
};

const AuthGuard = ({ Component, isPublic }: Props) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const hasValidUser = user?.roles?.includes(UserRole.KioskCustomer);
    if (!isPublic && !hasValidUser) {
      navigate(Routes.LOGIN.path, { replace: true });
    }
    if (isPublic && hasValidUser) {
      navigate(Routes.MENU.path, { replace: true });
    }
  }, [isPublic, navigate, user]);

  return <Component />;
};

export default AuthGuard;
