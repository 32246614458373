import { Modal as M } from 'antd';
import styled, { css } from 'styled-components';

import { StyledTextProps } from 'components/Text/styles';
import { theme } from 'theme';

interface Props {
  $height?: string;
  $width?: string;
  titleFontSize?: StyledTextProps['fontSize'] | string;
}

export const Modal = styled(M)<Props>(
  ({ $height = 'auto', $width = '535px', titleFontSize = 'h2' }) => css`
    width: ${$width};
    text-align: center !important;
    white-space: pre-wrap;

    .ant-modal-mask {
      background-color: rgba(0, 0, 0, 0.5) !important;
    }
    .ant-modal-content {
      padding: 35px !important;
      height: ${$height};
      align-items: center;
      justify-content: center;
      border-radius: 40px;
    }
    .ant-modal-title {
      width: 100%;
      border-bottom: none !important;
      padding: 20px 10px;
    }
    .ant-modal-header {
      border-bottom: none;
    }
    .ant-modal-header .ant-modal-title {
      font-weight: ${theme.fontWeight.bold};
      font-size: ${titleFontSize};
      line-height: ${theme.lineHeight.normal};
      text-align: center;
    }
    .ant-modal-centered {
      justify-content: center;
    }
  `,
);
