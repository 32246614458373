import { Radio } from 'antd';
import styled from 'styled-components';

import { theme } from 'theme';

export const StyledRadio = styled(Radio)<{ $checked?: boolean }>(
  ({ $checked }) => `  .ant-radio-inner {
    height: 25px;
    width: 25px;
    background-color: ${
      $checked ? `${theme.color.tan}` : `${theme.color.white}`
    };
    border: 3px solid ${theme.color.tan} !important;
  }
  
  .ant-radio-inner::after {
    background: ${$checked ? `${theme.color.black}` : `${theme.color.white}`};
    height: 12px;
    width: 12px;
    border: 5px solid ${theme.color.tan}
    text-align: center;
  }
  .ant-radio-checked {
    border-radius: 50%;
    border: 1px solid ${theme.color.tan} !important;
    background-color: ${theme.color.tan} !important;
  }
  `,
);
