import React from 'react';

import { ItemWrapper } from 'components/Layout';
import LongPressLogout from 'components/LongPress';
import KeyboardHOC from 'utils/context/keyboard';

import { Container, Wrapper } from './styles';

type Props = {
  logout?: boolean;
  children: React.ReactNode;
};

const LandingLayout: React.FC<Props> = ({ children, logout }) => {
  return (
    <Container>
      <Wrapper>
        {logout ? (
          <ItemWrapper $padding="275px 0px 0px 0px" $flex={2.75}>
            <LongPressLogout />
          </ItemWrapper>
        ) : (
          <ItemWrapper $padding="0" $flex={2} />
        )}
        <KeyboardHOC>{children}</KeyboardHOC>
      </Wrapper>
    </Container>
  );
};

export default LandingLayout;
