import { useContext, useEffect, useState, useMemo } from 'react';

import { message } from 'antd';

import {
  DishType,
  InventoryStatus,
  useKitchenMenuItemLazyQuery,
} from 'codegen/generated/graphql';
import { addToCart } from 'containers/MenuItemModal/helpers';
import { MenuItem } from 'utils/apollo/models';
import { cartMutations } from 'utils/apollo/mutations';
import { useAppContext } from 'utils/context';
import { CartContext } from 'utils/context/cartContext';
import { menuItemIdLogger } from 'utils/helpers';

import { useAgeRestriction } from './useAgeRestriction';

interface IuseMenuItemDetails {
  kitchenMenuItemId: string;
  handleModalClose: () => void;
  cartMenuItem?: MenuItem;
  isCheckout?: boolean;
}

export const useMenuItemDetails = ({
  kitchenMenuItemId,
  cartMenuItem,
  isCheckout,
  handleModalClose,
}: IuseMenuItemDetails) => {
  const [upsellItemId, setUpsellItemId] = useState('');
  const { menuItems: menuItem } = useAppContext();
  const { setRecalculate } = useContext(CartContext);
  const { ageConfirmed, isAgeRestricted, setAgeRestricted, confirmAge } =
    useAgeRestriction();

  const [getMenuItemQuery, { data: menuData, loading }] =
    useKitchenMenuItemLazyQuery({
      onCompleted: (data) => {
        cartMutations.createMenuItem(data.customerKitchenMenuItem);
      },
      onError: () => {
        message.error(`Failed to load menu item please try reloading the page`);
      },
    });

  useEffect(() => {
    if (upsellItemId || kitchenMenuItemId) {
      getMenuItemQuery({
        variables: {
          id: upsellItemId || kitchenMenuItemId,
        },
      });
    }
  }, [getMenuItemQuery, kitchenMenuItemId, upsellItemId]);

  const menuItemDetails = menuData?.customerKitchenMenuItem;

  const quantity = menuItem.quantity;

  const menuItemImg = menuItemDetails?.imgUrl;
  const soldOut = menuItemDetails?.inventoryStatus === InventoryStatus.SoldOut;
  const mealPackItems = menuItem.kitchenMenuItem?.mealPackItems;
  const upsellItems =
    menuItemDetails?.upsellMenuItems.map((item) => item.upsellItem) || [];

  const isAlcohol = useMemo(() => {
    return menuItemDetails?.dishTypes.includes(DishType.Alcohol);
  }, [menuItemDetails]);

  const handleClose = () => {
    upsellItemId ? setUpsellItemId('') : handleModalClose();
    cartMutations.deleteMenuItem(menuItem.id ?? '');
  };

  const handleAddCartClick = () => {
    menuItemIdLogger(menuItemDetails?.id);
    if (ageConfirmed === true || !isAlcohol) {
      addItemToCart();

      return false;
    } else if (isAlcohol && ageConfirmed === false) {
      return true;
    } else {
      return false;
    }
  };

  const handleAgeConfirm = () => {
    confirmAge(addItemToCart);
  };

  const addItemToCart = () => {
    addToCart({
      menuItem: cartMenuItem ?? menuItem,
      isEdit: isCheckout,
    });
    setRecalculate(true);
    handleModalClose();
  };

  return {
    isAgeRestricted,
    quantity,
    menuItem,
    menuItemDetails,
    menuItemImg,
    soldOut,
    mealPackItems,
    upsellItems,
    upsellItemId,
    loading,
    setUpsellItemId,
    setAgeRestricted,
    handleAddCartClick,
    handleAgeConfirm,
    handleClose,
  };
};
