import React from 'react';

import Text from 'components/Text';

import { StyledTag } from './styles';

interface Props {
  tagLabel: string;
}

const MenuItemTag = ({ tagLabel }: Props) => {
  return (
    <StyledTag>
      <Text fontSize="xxs" color="black" alignSelf="center">
        {tagLabel}
      </Text>
    </StyledTag>
  );
};

export default MenuItemTag;
