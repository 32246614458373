import { useEffect, useState } from 'react';

const PAYMENTS_FLAG = 'payments_mocked';

export const usePayment = () => {
  const [isPaymentMocked, setIsPaymentMocked] = useState(
    localStorage.getItem(PAYMENTS_FLAG)
      ? JSON.parse(localStorage.getItem(PAYMENTS_FLAG) ?? 'true')
      : false,
  );

  useEffect(() => {
    localStorage.setItem(PAYMENTS_FLAG, JSON.stringify(isPaymentMocked));
  }, [isPaymentMocked]);

  return [isPaymentMocked, setIsPaymentMocked];
};
