import React, { useState, useEffect, useCallback, useMemo } from 'react';

import {
  KitchenMenuItemQuery,
  ExtraItem as ModifierItem,
} from 'codegen/generated/graphql';
import { ItemWrapper, Row } from 'components/Layout';
import { StyledRadio } from 'components/Radio';
import Text from 'components/Text';
import { ExtraItem } from 'utils/apollo/models';
import { cartMutations } from 'utils/apollo/mutations';
import { priceFormatter } from 'utils/formatHelpers';

interface Props {
  extra: KitchenMenuItemQuery['customerKitchenMenuItem']['extras'][0];
  isCheckout?: boolean;
  menuItemId: string;
  menuItemExtras: ExtraItem[];
  requiredExtra: ExtraItem;
  mealPackItemId?: string | undefined;
}

const SingleExtraSelect = ({
  extra,
  isCheckout,
  menuItemId,
  menuItemExtras,
  requiredExtra,
  mealPackItemId,
}: Props) => {
  const [itemQuantity, setItemQuantity] = useState(1);

  const setSelectedExtras = menuItemExtras;

  const filteredExtras: ExtraItem[] = useMemo(() => {
    return setSelectedExtras.filter((i: ExtraItem) =>
      extra.items.some((extraItem) => extraItem.id === i.item.id),
    );
  }, [extra.items, setSelectedExtras]);

  const setSelectedExtra = useCallback(
    (extras: ExtraItem[]) => {
      if (!isCheckout) {
        cartMutations.editMenuItem({
          id: menuItemId,
          extras,
        });
      }

      if (isCheckout) {
        cartMutations.editCartMenuItem({
          cartId: 1,
          id: menuItemId,
          extras,
        });
      }
    },
    [isCheckout, menuItemId],
  );

  const handleExtraClick = useCallback(
    (
      item: KitchenMenuItemQuery['customerKitchenMenuItem']['extras'][0]['items'][0],
      quantity: number,
    ) => {
      setItemQuantity(quantity);

      const filteredSelectedExtras: ExtraItem[] = setSelectedExtras.filter(
        (selectedExtra: ExtraItem) =>
          extra.items.every(
            (extraItem) => extraItem.id !== selectedExtra.item.id,
          ),
      );

      // remove previous choice and replace with new choice
      setSelectedExtra([
        ...filteredSelectedExtras,
        {
          item: {
            name: item.name,
            id: item.id,
            price: item.price,
          } as ModifierItem,
          quantity,
          parentId: extra.id,
          singleSelect: extra.singleOption,
          mealPackItemId,
        },
      ]);

      if (extra.required && quantity === 0) {
        setItemQuantity(1);
      }
    },
    [extra, setSelectedExtra, setSelectedExtras, mealPackItemId],
  );

  useEffect(() => {
    if (extra.required && filteredExtras.length < 1 && !isCheckout) {
      handleExtraClick(requiredExtra.item, 1);
    }
  }, [
    extra.required,
    handleExtraClick,
    requiredExtra,
    filteredExtras.length,
    isCheckout,
  ]);

  useEffect(() => {
    if (isCheckout) {
      setItemQuantity(filteredExtras[0]?.quantity ?? 0);
    }
  }, [isCheckout, filteredExtras]);

  return (
    <ItemWrapper $margin="5px" key={extra.id}>
      <Row $justifyContent="flex-start" $width="770px">
        <Text fontSize="h3" fontWeight="bold" truncateText>
          {extra.name}
        </Text>
        {extra.required && (
          <Text
            alignSelf="center"
            fontSize="sm"
            fontWeight="thin"
            fontStyle="italic"
            marginLeft="xs"
          >
            * Required
          </Text>
        )}
      </Row>
      {extra.items.map((item) => {
        const isChecked = setSelectedExtras?.some(
          (i: ExtraItem) => i.item.id === item.id,
        );

        return (
          <ItemWrapper
            key={item.id}
            $flexDirection="row"
            $height="40px"
            $flex={1}
            $display="flex"
            $justify="space-evenly"
            $width="75%"
            $align="center"
            $margin="0"
          >
            <StyledRadio
              $checked={itemQuantity > 0 ? isChecked : false}
              checked={itemQuantity > 0 ? isChecked : false}
              onClick={() => {
                if (isChecked && extra.required) {
                  return;
                } else {
                  handleExtraClick(item, isChecked ? 0 : 1);
                }
              }}
              disabled={!item.isActive}
            />
            <Text
              fontSize="sm"
              fontWeight="normal"
              flex="2"
              marginLeft="sm"
              truncateText
            >
              {`${item.name} ${!item.isActive ? `(Sold out)` : ``}`}
            </Text>
            <Text fontSize="sm" fontWeight="normal" flex="1">
              {priceFormatter(item.price)}
            </Text>
          </ItemWrapper>
        );
      })}
    </ItemWrapper>
  );
};

export default SingleExtraSelect;
