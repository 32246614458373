import React from 'react';

import { Row } from 'components/Layout';
import Text from 'components/Text';
import { priceFormatter } from 'utils/formatHelpers';

interface Props {
  mpItems: any;
  selectedExtras: any;
}

const CartMealPackItems = ({ mpItems, selectedExtras }: Props) => {
  return (
    <>
      {mpItems.map((item: any) => {
        const mpItemExtras = item.extras;
        const targetIds = mpItemExtras.map(
          (mpItemExtra: any) => mpItemExtra.id,
        );
        const filteredExtras = selectedExtras.filter((selExtra: any) =>
          targetIds.includes(selExtra.parentId),
        );

        return (
          <>
            <Row
              key={item.id}
              $justifyContent="space-evenly"
              $padding="0px 6px"
              $margin="10px 0px 0px 0px"
            >
              <Row $justifyContent="flex-start">
                <Text fontWeight="bold" marginRight="md" fontSize="xs">
                  {item.name}
                </Text>
              </Row>
            </Row>
            {filteredExtras.map((extra: any) => {
              return (
                <Row
                  key={extra.item.id}
                  $justifyContent="space-evenly"
                  $padding="2px 6px"
                >
                  <Row $justifyContent="flex-start">
                    <Text marginRight="md" fontSize="xs">
                      {extra.quantity}X
                    </Text>
                    <Text fontSize="xs" truncateText>
                      {extra.item.name}
                    </Text>
                  </Row>
                  <Text fontSize="xs">
                    {priceFormatter(extra.item.price * extra.quantity)}
                  </Text>
                </Row>
              );
            })}
          </>
        );
      })}
    </>
  );
};

export default CartMealPackItems;
