import { Tag } from 'antd';
import styled from 'styled-components';

import { paddingX, radius } from 'utils/styleHelpers';

export const StyledTag = styled(Tag)`
  ${radius('xSmall')}
  ${paddingX('md')}
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  margin-bottom: 10px;
`;
