import React, { useState } from 'react';

import { sumBy } from 'lodash';

import {
  KitchenMenuItemQuery,
  ExtraItem as ModifierItem,
} from 'codegen/generated/graphql';
import { ItemWrapper, Row } from 'components/Layout';
import Text from 'components/Text';
import { ExtraItem } from 'utils/apollo/models';
import { cartMutations } from 'utils/apollo/mutations';
import { priceFormatter } from 'utils/formatHelpers';

import MultiSelectQuantityCounter from './MultiSelectQuantityCounter';

interface Props {
  extra: KitchenMenuItemQuery['customerKitchenMenuItem']['extras'][0];
  isCheckout?: boolean;
  menuItemId: string;
  menuItemExtras: ExtraItem[];
  mealPackItemId?: string | undefined;
}

const MultiExtraSelect = ({
  extra,
  isCheckout,
  menuItemId,
  menuItemExtras,
  mealPackItemId,
}: Props) => {
  const handleMutations = (extraItem: ExtraItem) => {
    let updated = [...menuItemExtras];
    const existing = menuItemExtras.find(
      (extra: ExtraItem) => extra.item.id === extraItem.item.id,
    );

    if (!existing) {
      updated = [...menuItemExtras, extraItem];
    } else {
      updated = menuItemExtras.map((extra) => {
        return extra.item.id === extraItem.item.id ? extraItem : extra;
      });
    }

    if (!isCheckout) {
      cartMutations.editMenuItem({ id: menuItemId, extras: updated });
    } else {
      cartMutations.editCartMenuItem({
        cartId: 1,
        id: menuItemId,
        extras: updated,
      });
    }
  };

  const configureModTotals = () => {
    const modItemArray = [] as any[];

    extra.items.forEach((item) => {
      const getItemCount = (item: ModifierItem) => {
        if (!isCheckout) {
          return 0;
        } else {
          const itemSelected = menuItemExtras.find(
            (selected) => selected.item.id === item.id,
          );

          return itemSelected ? itemSelected.quantity : 0;
        }
      };

      modItemArray.push({
        itemId: item.id,
        itemCount: getItemCount(item),
      });

      if (!isCheckout && extra.defaultItemId === item.id) {
        handleMutations({
          item: {
            name: item.name,
            id: item.id,
            price: item.price,
          } as ModifierItem,
          quantity: 1,
          parentId: extra.id,
          mealPackItemId,
          singleSelect: false,
        });
      }
    });

    return modItemArray;
  };

  const [modTotals, setModTotals] = useState<any>(configureModTotals);
  const [maxTotalReached, setMaxTotalReached] = useState(
    sumBy(modTotals, 'itemCount') === extra.maximum,
  );

  const handleExtraClick = (
    item: KitchenMenuItemQuery['customerKitchenMenuItem']['extras'][0]['items'][0],
    quantity: number,
  ) => {
    handleMutations({
      item: {
        name: item.name,
        id: item.id,
        price: item.price,
      } as ModifierItem,
      quantity,
      parentId: extra.id,
      mealPackItemId,
      singleSelect: false,
    });
  };

  const handleModChange = (itemId: string, quantity: number) => {
    const updated = modTotals.map((item: any) => {
      return item.itemId === itemId ? { itemId, itemCount: quantity } : item;
    });
    setModTotals(updated);
    setMaxTotalReached(sumBy(updated, 'itemCount') === extra.maximum);
  };

  return (
    <ItemWrapper $margin="5px" key={extra.id}>
      <Row $justifyContent="flex-start" $width="770px">
        <Text fontSize="h3" fontWeight="bold" truncateText>
          {extra.name}
        </Text>
        {extra.required && (
          <Text
            alignSelf="center"
            fontSize="sm"
            fontWeight="thin"
            fontStyle="italic"
            marginLeft="xs"
          >
            * Required
          </Text>
        )}
      </Row>
      {extra.items.map((item) => {
        const getQuantity = () => {
          if (!isCheckout) {
            return 0;
          } else {
            const selected = menuItemExtras.find(
              (selected) => selected.item.id === item.id,
            );

            return selected ? selected.quantity : 0;
          }
        };

        return (
          <ItemWrapper
            key={item.id}
            $flexDirection="row"
            $height="40px"
            $flex={1}
            $display="flex"
            $justify="space-evenly"
            $width="59%"
            $align="center"
            $margin="0"
          >
            <>
              <MultiSelectQuantityCounter
                quantity={getQuantity()}
                onChange={(quantity: number) => {
                  handleModChange(item.id, quantity);
                  handleExtraClick(item, quantity);
                }}
                disableAdd={maxTotalReached || !item.isActive}
              />
            </>
            <Text
              fontSize="sm"
              fontWeight="normal"
              flex="7"
              marginLeft="sm"
              truncateText
            >
              {`${item.name} ${!item.isActive ? `(Sold out)` : ``}`}
            </Text>
            <Text fontSize="sm" fontWeight="normal" flex="1">
              {priceFormatter(item.price)}
            </Text>
          </ItemWrapper>
        );
      })}
    </ItemWrapper>
  );
};

export default MultiExtraSelect;
