import { Button } from 'antd';
import styled from 'styled-components';

import { theme } from 'theme';

export const UpsellItemsWrapper = styled.div<{ isCart?: boolean }>(
  ({ isCart }) => `
  display: flex;
  gap: 22px;
  padding: 0 ${isCart ? '92px' : '64px'};
  margin: 0 ${isCart ? '-92px' : '-64px'};
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: 0;
  ::-webkit-scrollbar {
    display: none;
  }
`,
);

export const ItemWrapper = styled.div`
  min-width: 200px;
`;

export const ItemImgCard = styled.div<{ imgUrl: string }>(
  ({ imgUrl }) => `
  height: 146px;
  width: 100%;
  border-radius: 12px;
  position: relative;
  background-image: url("${imgUrl}");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`,
);

export const NamePriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  p {
    color: ${theme.color.black};
    font-size: 14px;
    font-weight: 600;
  }
`;

export const ItemButton = styled(Button)`
  height: 30px;
  background: ${theme.color.white};
  color: #161616;
  border: 1px solid #ece1d4;
  border-radius: 30px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  bottom: 4px;
  right: 4px;
  img {
    margin-left: 8px;
  }
`;
