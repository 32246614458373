import React, { useContext } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';

import { Spin } from 'antd';

import { AuthContext } from 'authContext';
import DevToolsWidget from 'containers/DevToolsWidget';
import LandingLayout from 'containers/LandingLayout';
import CheckoutPage from 'pages/checkout';
import ClosedModal from 'pages/closed';
import DeveloperToolsComponent from 'pages/developerTools';
import ForgotPassword from 'pages/forgotPassword';
import LoginComponent from 'pages/login';
import MenuComponent from 'pages/menu';
import SettingsComponent from 'pages/settings';
import { PaymentProvider } from 'paymentContext';
import IdleTimeoutHOC from 'utils/context/idleTimeout';

import AuthGuard from './AuthGuard';
import routes from './routes';

export const Router = () => {
  const { loading } = useContext(AuthContext);

  if (loading) {
    return (
      <LandingLayout>
        <Spin tip="Loading..." spinning={loading} />
      </LandingLayout>
    );
  }

  return (
    <HashRouter>
      <PaymentProvider>
        <Routes>
          <Route
            path={routes.LOGIN.path}
            element={<AuthGuard isPublic Component={LoginComponent} />}
          />
          <Route
            path={routes.RESET_PASSWORD.path}
            element={<AuthGuard isPublic Component={ForgotPassword} />}
          />
          <Route
            path={routes.MENU.path}
            element={<AuthGuard Component={MenuComponent} />}
          />
          <Route
            path={routes.CHECKOUT.path}
            element={<AuthGuard Component={CheckoutPage} />}
          />
          <Route
            path={routes.SETTINGS.path}
            element={<AuthGuard Component={SettingsComponent} />}
          />
          <Route
            path={routes.DEVELOPER_TOOLS.path}
            element={<AuthGuard Component={DeveloperToolsComponent} />}
          />
        </Routes>
        <ClosedModal />
        <IdleTimeoutHOC />
        <DevToolsWidget />
      </PaymentProvider>
    </HashRouter>
  );
};
