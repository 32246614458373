import React, { useState } from 'react';

import { CartUpsellItemsWrapper } from 'containers/Checkout/styles';
import MenuItemModal from 'containers/MenuItemModal';
import { UpsellItems } from 'containers/MenuItemModal/components/UpsellItems';
import { UpsellItem } from 'containers/MenuItemModal/types';

interface ICheckoutUpsellItems {
  items: UpsellItem[];
}

export const CheckoutUpsellItems = ({ items }: ICheckoutUpsellItems) => {
  const [menuItemId, setMenuItemId] = useState('');
  const [showMenuItemModal, setShowMenuItemModal] = useState(false);

  const handleModalOpen = (id: string) => {
    setMenuItemId(id);
    setShowMenuItemModal(true);
  };

  const handleModalClose = () => {
    setMenuItemId('');
    setShowMenuItemModal(false);
  };

  if (items.length === 0) return null;

  return (
    <CartUpsellItemsWrapper>
      <UpsellItems items={items} viewItem={handleModalOpen} isCartUpsell />
      {showMenuItemModal && (
        <MenuItemModal
          isCheckout={false}
          handleModalClose={handleModalClose}
          kitchenMenuItemId={menuItemId}
          visible={showMenuItemModal}
        />
      )}
    </CartUpsellItemsWrapper>
  );
};
