import React, { useContext, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { useAgeRestriction } from 'hooks/useAgeRestriction';
import { uniqueId } from 'lodash';

import { DishType } from 'codegen/generated/graphql';
import InfoModal from 'components/InfoModal';
import Text from 'components/Text';
import { addToCart } from 'containers/MenuItemModal/helpers';
import { UpsellItem } from 'containers/MenuItemModal/types';
import { CartContext } from 'utils/context/cartContext';
import { priceFormatter } from 'utils/formatHelpers';
import { menuItemIdLogger } from 'utils/helpers';

import {
  ItemWrapper,
  ItemImgCard,
  UpsellItemsWrapper,
  NamePriceWrapper,
  ItemButton,
} from './style';

interface Props {
  items: UpsellItem[];
  viewItem: (id: string) => void;
  isCartUpsell?: boolean;
}

export const UpsellItems = ({ items, viewItem, isCartUpsell }: Props) => {
  const { ageConfirmed, isAgeRestricted, setAgeRestricted, confirmAge } =
    useAgeRestriction();
  const { setRecalculate } = useContext(CartContext);

  const [tempItem, setTempItem] = useState<UpsellItem>();

  const handleAddCartClick = (item: UpsellItem) => {
    menuItemIdLogger(item?.id);

    const isAlcohol = item?.dishTypes.includes(DishType.Alcohol);

    if (ageConfirmed || !isAlcohol) {
      addItemToCart(item);

      return false;
    } else if (isAlcohol && !ageConfirmed) {
      setTempItem(item);

      return true;
    } else {
      return false;
    }
  };

  const handleAgeConfirm = () => {
    if (tempItem) {
      confirmAge(() => addItemToCart(tempItem));
      setTempItem(undefined);
    }
  };

  const addItemToCart = (item: UpsellItem) => {
    const menuItem = {
      id: uniqueId('menu_item_'),
      kitchenMenuItem: item,
      selectedExtras: [],
      price: item.price,
      quantity: 1,
      totalPrice: item.price,
      sortNumber: 1,
    };
    addToCart({
      menuItem,
    });
    setRecalculate(true);
    message.success(`${item.name} added to cart`);
  };

  if (items.length === 0) return null;

  return (
    <>
      <Text
        marginTop={isCartUpsell ? 30 : 70}
        fontSize="xl"
        spaceAfter={18}
        fontWeight="semiBold"
      >
        {isCartUpsell ? 'Extras & More' : 'Frequently Bought Together'}
      </Text>
      <UpsellItemsWrapper isCart={isCartUpsell}>
        {items.map((item) => {
          const { id, name, price, imgUrl, hasExtras } = item;

          return (
            <ItemWrapper key={id}>
              <ItemImgCard imgUrl={imgUrl || './svg/menu-item.svg'}>
                <ItemButton
                  onClick={() => {
                    hasExtras
                      ? viewItem(id)
                      : setAgeRestricted(handleAddCartClick(item));
                  }}
                >
                  {hasExtras ? 'View' : 'Add'} <PlusOutlined />
                </ItemButton>
              </ItemImgCard>
              <NamePriceWrapper>
                <Text>{name}</Text>
                <Text marginLeft={8}>{priceFormatter(price)}</Text>
              </NamePriceWrapper>
            </ItemWrapper>
          );
        })}
      </UpsellItemsWrapper>
      <InfoModal
        title="You must be at least 21 years old to order this item."
        visible={isAgeRestricted}
        buttonText="I am 21 or older"
        basic={true}
        message="Please have your ID ready for our staff when you pick up your items."
        onClickBack={() => setAgeRestricted(false)}
        onClickAction={handleAgeConfirm}
      />
    </>
  );
};
