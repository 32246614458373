import React, { createContext, useContext } from 'react';

import { usePayment } from './utils/hooks';

interface State {
  isPaymentMocked: boolean;
  setIsPaymentMocked: any;
}

const PaymentContext = createContext({
  isPaymentMocked: true,
  setIsPaymentMocked: () => {},
}) as React.Context<State>;

type Props = {
  children: React.ReactNode;
};

export const PaymentProvider = ({ children }: Props) => {
  const [isPaymentMocked, setIsPaymentMocked] = usePayment() as [any, any];

  return (
    <PaymentContext.Provider
      value={{
        isPaymentMocked,
        setIsPaymentMocked,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export const usePaymentCtx = () => useContext(PaymentContext);
