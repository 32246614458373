import React from 'react';

import { Wrapper, Scrollable } from './styles';

type Props = {
  children: React.ReactNode;
};

const Scroll = ({ children }: Props) => {
  return (
    <Wrapper>
      <Scrollable>{children}</Scrollable>
    </Wrapper>
  );
};

export default Scroll;
