import React, {
  FC,
  createContext,
  useReducer,
  Dispatch,
  useRef,
  useEffect,
  useContext,
  MutableRefObject,
} from 'react';

import OnScreenKeyboard from '../../components/Keyboard';

export enum ACTIONS {
  SHOW = 'show',
  HIDE = 'hide',
  ADD_INPUT_REFERENCE = 'addInputReference',
  SET_FOCUSED_INPUT = 'setFocusedInput',
  SET_ON_CHANGE_HANDLER = 'setOnChangeHandler',
  NUM_LOCK = 'setNumLock',
  NON_NUM_LOCK = 'removeNumLock',
}

type Action = {
  type: ACTIONS;
  [propName: string]: any;
};

const initialState = {
  visible: false,
  focusedInputName: null,
  onChange: null,
  isNumLock: false,
};

const context = {
  state: initialState,
  dispatch: () => {},
} as {
  state: typeof initialState;
  dispatch: Dispatch<Action>;
  keyboard?: MutableRefObject<any>;
};

const elements = new WeakSet();

const reducer = (state: typeof initialState, action: Action) => {
  switch (action.type) {
    case ACTIONS.SHOW:
      return { ...state, visible: true };
    case ACTIONS.HIDE:
      return { ...state, visible: false };
    case ACTIONS.NUM_LOCK:
      return { ...state, isNumLock: true };
    case ACTIONS.NON_NUM_LOCK:
      return { ...state, isNumLock: false };
    case ACTIONS.ADD_INPUT_REFERENCE:
      elements.add(action.payload.ref.current.input);

      return state;
    case ACTIONS.SET_FOCUSED_INPUT:
      return { ...state, visible: true, focusedInputName: action.payload.id };
    case ACTIONS.SET_ON_CHANGE_HANDLER:
      return { ...state, onChange: action.payload };
    default:
      return state;
  }
};

export const KeyboardContext = createContext(context);

type Props = {
  children: React.ReactNode;
};

const KeyboardContextComponent: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const keyboard = useRef<any>();

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (!keyboard.current) return;

      if (e.target instanceof HTMLInputElement && e.target.type === 'number') {
        dispatch({
          type: ACTIONS.NUM_LOCK,
        });
      } else {
        dispatch({
          type: ACTIONS.NON_NUM_LOCK,
        });
      }

      if (
        !keyboard.current.keyboardDOM.contains(e.target) &&
        !elements.has(e.target ?? {})
      ) {
        dispatch({ type: ACTIONS.HIDE });
      }
    };
    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, []);

  return (
    <KeyboardContext.Provider value={{ state, dispatch, keyboard }}>
      <>
        <OnScreenKeyboard
          isVisible={state.visible}
          inputName={state.focusedInputName}
          isNumLock={state.isNumLock}
          ref={keyboard}
          onChange={state.onChange}
        />
        {children}
      </>
    </KeyboardContext.Provider>
  );
};

export const useKeyboard = () => useContext(KeyboardContext);

export default KeyboardContextComponent;
